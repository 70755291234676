<template>
  <!-- :close-on-click-modal="false" -->
  <div class="gbook">
    <div style="height:0.9rem; line-height:0.9rem; color:#414141; font-size:0.28rem; font-weight: bold; padding:0 0.3rem; text-align: center; margin-bottom:0.6rem; border-bottom: #f3f3f3 solid 1px; background: #f9f9f9;">企业信息填写</div>
    <el-form ref="ruleForm" :model="formData" :rules="rules" label-width="2.2rem">
      <el-form-item label="" prop="linkmanName">
        <div class="lable"><i>*</i>联系人：</div>
        <el-input v-model="formData.linkmanName" style="width:87%" placeholder="请输入联系人" />
      </el-form-item>
      <el-form-item label="" prop="phoneNumber">
        <div class="lable"><i>*</i>联系电话：</div>
        <el-input v-model="formData.phoneNumber" style="width:87%" placeholder="请输入联系电话" type="number" />
      </el-form-item>
      <el-form-item label="" prop="goodsName">
        <div class="lable"><i>*</i>发布货物名称：</div>
        <el-input v-model="formData.goodsName" style="width:87%" placeholder="请输入发布货物名称" />
      </el-form-item>
      <el-form-item label="" prop="goodsWeight">
        <div class="lable"><i>*</i>数量(吨)：</div>
        <el-input v-model="formData.goodsWeight" style="width:87%" placeholder="请输入数量(吨)" type="number" />
      </el-form-item>
      <el-form-item label="" prop="distance">
        <div class="lable"><i>*</i>运距(公里)：</div>
        <el-input v-model="formData.distance" style="width:87%" placeholder="请输入运距(公里)" type="number" />
      </el-form-item>
      <el-form-item label="" prop="areaId">
        <div class="lable"><i>*</i>发货地：</div>
        <!-- filterable -->
        <el-cascader ref="myCascader" :options="list" clearable :props="countryObj" style="width:87%" placeholder="请选择发货地" @change="proviceChange" @getCheckedNodes="Nodes" />
      </el-form-item>
      <el-form-item label="" prop="isTaxpayer">
        <div class="lable" style="line-height:0.28rem; top: 0;"><i>*</i>是否是&nbsp;&nbsp;<br>一般纳税人：</div>
        <el-radio-group v-model="formData.isTaxpayer">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="" prop="isPlatformUser">
        <div class="lable" style="line-height:0.28rem; top: 0;"><i>*</i>是否使用过&nbsp;&nbsp;<br>网络货运平台：</div>
        <el-radio-group v-model="formData.isPlatformUser">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>

    </el-form>
    <div style="margin:0.9rem 0 0 0; text-align: center;">
      <div class="buttons" @click="submit('ruleForm')">提 交</div>
      <!-- <span class="buttons" style="border:#dcdfe6 solid 1px; background:#fff; color:#414141; margin-left:0.5rem;" @click="cannelSave">取 消</span> -->
    </div>
  </div>

</template>

<script>
import '../assets/js/wap.js'
export default {
  msg: '意向合作',
  data() {
    return {
      visible: false,
      jobInfo: {},
      list: [],
      countryObj: {
        value: 'key',
        label: 'value', 		// 字段对应的文字
        children: 'childList',
        expandTrigger: 'click'
        // emitPath: true
        // checkStrictly: true
      },
      formData: {
        linkmanName: '', // 联系人
        phoneNumber: '', // 联系电话
        enterpriseName: '', // 企业名称
        goodsName: '', // 发布货物名称
        goodsWeight: '', // 数量
        distance: '', // 距离
        areaId: '', // 地址ID
        merName: '', // 地址全称
        isTaxpayer: '', // 是否为一般纳税人(true是false不是)
        isPlatformUser: '' // 是否使用过网络货运平台(true是false不是)
      },
      rules: {
        linkmanName: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        phoneNumber: [{ required: true, message: '请输入联系电话', trigger: 'blur' }],
        goodsName: [{ required: true, message: '请输入货物名称', trigger: 'blur' }],
        goodsWeight: [{ required: true, message: '请输入数量(吨)', trigger: 'blur' }],
        distance: [{ required: true, message: '请输入运距(公里)', trigger: 'blur' }],
        areaId: [{ required: true, message: '请选择发货地', trigger: 'blur' }],
        isTaxpayer: [{ required: true, message: '请选择是否为一般纳税人', trigger: 'blur' }],
        isPlatformUser: [{ required: true, message: '请选择是否使用过网络货运平台', trigger: 'blur' }]
      }
    }
  },
  created() {
    this.initAddressArea()
  },
  methods: {
    init() {
      this.visible = true
    },
    initAddressArea() {
      this.$http.post('api/ContactUs/GetAreaComboBox', {}).then(res => {
        this.list = res.data
        console.log(this.list, '返回来的下拉内容')
      }).catch(error => { console.log(error) })
    },
    Nodes(data) {
      console.log(data, '3333333333333333333333')
    },
    proviceChange(data, arr) {
      this.formData.areaId = data[0] + ',' + data[1] + ',' + data[2]
      this.formData.merName = (this.$refs.myCascader.getCheckedNodes()[0].pathLabels).toString()
      // console.log(this.formData.merName, '0000000000000000000')
    },
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.save()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetFormData() {
      // this.$refs.ruleForm.resetFields()
      this.formData = {
        linkmanName: '', // 联系人
        phoneNumber: '', // 联系电话
        enterpriseName: '', // 企业名称
        goodsName: '', // 发布货物名称
        goodsWeight: '', // 数量
        distance: '', // 距离
        areaId: '', // 地址ID
        merName: '', // 地址全称
        isTaxpayer: '', // 是否为一般纳税人(true是false不是)
        isPlatformUser: '' // 是否使用过网络货运平台(true是false不是)
      }
    },
    save() {
      this.$http.post('api/ContactUs/Add', this.formData).then(res => {
        if (res.code === 1) {
          // this.$message({ showClose: true, type: 'success', message: '提交成功！我们会尽快联系您！', duration: '8000' })
          alert('提交成功！我们会在3个工作日内联系您！')
          // this.$alert('我们会在3个工作日内联系您！', '提交成功', {
          //   confirmButtonText: '关闭'
          //   // callback: action => {
          //   //   this.$message({
          //   //     type: 'info',
          //   //     message: `action: ${action}`
          //   //   })
          //   // }
          // })

          setTimeout(() => {
            this.resetFormData()
          }, 0)
          this.visible = false
        }
      }).catch(error => { console.log(error) })
    },
    //
    cannelSave() {
      this.visible = false
      this.resetFormData()
    }
  }
}
</script>

<style  lang='scss'>
@import "../assets/css/wap.css";
.gbook{
  // font-size: 0.3rem;
   border-top: 1px solid rgb(232, 232, 232);
  .buttons{background: #0062db; color: #fff;border-radius: 0.8rem; font-size: 0.28rem; text-align: center; cursor: pointer; padding:0.18rem 0; width: 84%; margin:0 auto;}
  .buttons:hover{ opacity: .8;}
  .lable{position:absolute; top: 0.1rem; z-index:1; left:-2.1rem; text-align: right; width: 2rem; line-height: 0.48rem; font-size: 0.24rem; color: #666;
    i{color: #f00; padding-right: 2px;}
  }
}
.el-form-item__label{ line-height: 0.28rem; font-size: 0.24rem;}
.el-input__inner{ height: 0.65rem; line-height: 0.65rem;}
.el-form-item {margin-bottom: 0.4rem;}
// .el-radio__inner {
//     border: 1px solid #DCDFE6;
//     border-radius: 100%;
//     width: 0.24rem;
//     height: 0.24rem;
//     background-color: #FFF;
//     cursor: pointer;
//     box-sizing: border-box;
// }
.el-form-item__content {
    line-height: 0.5rem;
    position: relative;
    font-size: 14px;
}
.el-input{
  font-size: 0.23rem;
}
.el-form-item__error{font-size: 0.2rem;}

// 地址下拉
.el-cascader-menu{min-width: 1rem;width: 2.1rem;}
.el-cascader-node__label{padding:0; font-size: 0.2rem;}
.el-cascader-node{ padding:0 0.15rem;}
.el-cascader-node__postfix{ right: 0.1rem;}
</style>
